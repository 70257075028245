import { Component } from '@angular/core';

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import iCalendarPlugin from '@fullcalendar/icalendar';
import deLocale from '@fullcalendar/core/locales/de';
import bootstrapPlugin from '@fullcalendar/bootstrap';

import { SelectItem } from 'primeng/api/selectitem';

import calendarConfig from './../assets/data/calendars.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'calendar-primeng';
  calendar: Calendar;
  in: boolean = false;
  blocked: boolean = false;
  selectedCals: any[] = [];
  calendarsConfiguration: SelectItem[] = [];
  genEvents: [string, any][] = [];
  eventsRendered: boolean = false;
  categories: any[] = [{"id": "organization", "name": "Organisation"},
                       {"id": "location", "name": "Örtlichkeit"},
                       {"id": "other", "name": "Sonstiges"}
                      ];

  ngOnInit() {
    console.log('Reading calendars json files');
    console.log(calendarConfig);
    this.calendarsConfiguration = calendarConfig;
    for (let cal of this.calendarsConfiguration) {
      if (cal.value.default) {
        this.selectedCals.push(cal.value);
      }
    }
    this.calendar = this.createCalendar('calendar');
    this.calendar.render();
  }

  dateChanged() {
    this.blocked = true;
    this.calendar.removeAllEventSources();
    this.genEvents = [];
    for (let cal of this.calendarsConfiguration) {
      this.calendar.addEventSource(cal.value);
    }
    this.calendar.render();
    this.postDateChanged();
  }

  postDateChanged() {
    return new Promise(resolve => {
      setTimeout(() => {
        this.generateEvents();
        this.showGeneratedEvents();
      }, 500);
    });
  }

  /**
   * This function delays its execution for 0.5 second (asynchron).
   * This ensures that calendar source have been changed fully (and all events are available).
   */
  postSourceChange() {
    return new Promise(resolve => {
      setTimeout(() => {
        this.showGeneratedEvents();
      }, 100);
    });
  }

  createCalendar(div: string): Calendar {
    const calendarEl = document.getElementById(div);
    const calendarPlugins = [
      dayGridPlugin,
      timeGrigPlugin,
      listPlugin,
      iCalendarPlugin,
      bootstrapPlugin
    ];
    return new Calendar(calendarEl, {
      initialView: 'dayGridMonth',
      locale: deLocale,
      navLinks: true,
      plugins: calendarPlugins,
      eventSources: [],
      themeSystem: 'bootstrap',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listMonth'
      },
      datesSet: function(dateinfo) {
        this.dateChanged();
      }.bind(this),
      editable: true
    });
  }


  generateEvents() {
    // Generate Events
    this.genEvents = [];
    for (const event of this.calendar.getEvents()) {
      if ((event.extendedProps.location != null) && (event.extendedProps.location != '')) {
        const nEvent = { title: event.title, start: event.start, end: event.end, allDay: event.allDay };
        this.genEvents.push([event.extendedProps.location, nEvent]);
      }
    }
    this.onChange();
  }

  showGeneratedEvents() {
    for (const pair of this.genEvents) {
      for (const cal of this.calendar.getEventSources()) {
        if (cal.internalEventSource._raw.category === 'location' &&
          this.simplifyString(cal.internalEventSource.extendedProps.name).includes(this.simplifyString(pair[0]))) {
          this.calendar.addEvent(pair[1], cal);
        }
      }
    }
  }

  simplifyString(text: String) {
    const regex = /\s/gi;
    return text.toLowerCase().replace(regex, "");
  }

  /** Handle checkbox changes */
  onChange() {
    this.calendar.removeAllEventSources();
    for (const cal of this.selectedCals) {
      this.calendar.addEventSource(cal);
    }
    this.postSourceChange();
    this.calendar.render();
  }

  applyFontColor(col: any) {
    return { color: col };
  }

  removeCal(id: number) {
    for (let i = 0; i < this.selectedCals.length; i++) {
      const cal = this.selectedCals[i];
      if (cal.id === id) {
        this.selectedCals.splice(i, 1);
      }
    }
    this.onChange();
  }
}

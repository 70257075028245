<p-multiSelect [options]="calendarsConfiguration" [filter]="false" (onChange)="onChange()" [(ngModel)]="selectedCals" [panelStyle]="{minWidth:'12em'}"
    filter="false">
    <ng-template let-value pTemplate="selectedItems">
        <div *ngFor="let category of categories">
            <div>{{category.name}}</div>
            <ng-container *ngFor="let cal of value" >
                <ng-container *ngIf="category.id===cal.category">
                    <div class="selectedItem ui-corner-all">
                        <i class="pi pi-circle-on circle-selected" [ngStyle]="applyFontColor(cal.color)" style="font-size:1em"></i>
                        <div style="font-size:1em;display:inline;color:#000000">{{cal.name}}</div>
                        <i class="pi pi-times times" (click)="removeCal(cal.id)"></i>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <span *ngIf="!value || value.length === 0" class="ui-multiselected-empty-token ui-corner-all">Bitte Kalender wählen</span>
    </ng-template>
    <ng-template let-cal pTemplate="item">
        <i class="pi pi-circle-on circle" [ngStyle]="applyFontColor(cal.value.color)"></i>
        <div style="font-size:1em;display:inline;color:#000000">{{cal.label}}</div>
    </ng-template>
</p-multiSelect>

<div id="calendar" class="calendar"></div>